
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchBar from '@/components/search-bars/SearchBar.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
import moment from 'moment';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'institute-wise-training-statistic',
  components: {
    axios,
    Form,
    Datatable,
    SearchBar,
    Multiselect,
    Swal,
  },
  data() {
    return {
      moment: '' as any,
      api_url: '',
      load: false,
      loading: false,
      institutes: [] as any,
      association: [] as any,
      tranches: [] as any,
      entity_id: '' as any,
      institute_id: '' as any,
      tranche_id: [] as any,
      optionsTranche: [] as any,
      instituteTrainningStatistic: [] as any,
      componentKey: 0,
      showBtn: false,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getAssociation();
    await this.getTranches();
    this.moment = moment;
  },
  methods: {

    async printPdf() {
      let formData = new FormData();

      formData.set('tranche', this.tranche_id);
      formData.set('entity', this.entity_id);
      formData.set('institute', this.institute_id);

      let data = `${this.VUE_APP_API_URL}/api/report/institute-wise-training-statistic_print`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('tranche', this.tranche_id);
      formData.set('entity', this.entity_id);
      formData.set('institute', this.institute_id);
      await ApiService.post('report/institute-wise-training-statistic_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('tranche', this.tranche_id);
      formData.set('entity', this.entity_id);
      formData.set('institute', this.institute_id);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/institute-wise-training-statistic_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'institute-wise-training-statistics.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getTranches() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.tranches.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.association = response.data.data;
          console.log(response);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async trainingInstitute() {
      await ApiService.get('institute/list?entity_id=' + this.entity_id)
        .then((response) => {
          this.institutes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    showBtnStatus() {
      this.showBtn = true;
    },

    async instituteTrainningStatisticReport() {
      if (
        this.entity_id != false &&
        this.institute_id != false &&
        this.tranche_id != false
      ) {
        this.load = true;
        await ApiService.get(
          'report/institute-wise-training-statistic?entity=' +
          this.entity_id +
          '&institute=' +
          this.institute_id +
          '&tranche=' +
          this.tranche_id
        )
          .then((response) => {
            this.instituteTrainningStatistic = response.data.data;

            this.componentKey += 1;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          text: 'Please Select All Required Field!',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok, got it!',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        });
      }
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
